<template>
    <section class="section-categorias-component py-2">
        <cargando v-if="cargando" />
        <div class="row mx-0 position-relative">
            <!-- Arrows -->
            <div v-if="!dosLineas && primerLinea.length > 6" class="d-middle-center position-absolute cr-pointer bg-white rounded-circle shadow z-1" style="width:32px;height:32px;left:5px;top:36%;" @click="leftScroll">
                <i class="icon-angle-left text-general f-20" />
            </div>
            <div v-if="!dosLineas && primerLinea.length > 6" class="d-middle-center position-absolute cr-pointer bg-white rounded-circle shadow z-1" style="width:32px;height:32px;right:50px;top:36%;" @click="rightScroll">
                <i class="icon-angle-right text-general f-20" />
            </div>
            <!-- Arrows -->
            <div class="text-center" style="width:35px;">
                <el-tooltip content="Inactivo" placement="bottom" effect="light">
                    <i v-if="!sectionActive" class="icon-eye-off f-18 text-general cr-pointer" />
                </el-tooltip>
            </div>
            <div id="container" class="col  px-0 overflow-auto custom-scroll">
                <div class="row mx-0 align-items-center text-gris2 f-500 mb-2">
                    Sección categorías
                    <span class="ml-4 text-general f-20 f-600">
                        <el-tooltip content="Clics de la sección" placement="bottom" effect="light">
                            <i class="icon-cursor" />
                        </el-tooltip>
                        {{ totalClicks }}
                    </span>
                </div>
                <template v-if="verData">
                    <div :class="`${dosLineas ? 'mb-3' : ''}`" style="display:inline-flex;">
                        <div v-for="(i, idx) in primerLinea" :key="`slider-${idx}`" class="card-categoria br-6 mr-4">
                            <img :src="i.banner" width="100%" height="180px" class="bg-white br-6" />
                            <p class="mt-2 nombre-categoria text-center f-16 text-general br-6 mt-2">
                                {{ i.nombre }}
                            </p>
                            <p class="text-general text-center f-20 f-600 mt-2">
                                <el-tooltip content="Clics" placement="bottom" effect="light">
                                    <i class="icon-cursor" />
                                </el-tooltip>
                                {{ i.clicks }}
                            </p>
                        </div>
                    </div>
                    <template v-if="dosLineas">
                        <div class="row" />
                        <div style="display:inline-flex;">
                            <div v-for="(i, idx) in segundaLinea" :key="`slider2-${idx}`" class="card-categoria br-6 mr-4">
                                <img :src="i.banner" width="100%" height="180px" class="bg-white br-6" />
                                <p class="mt-2 nombre-categoria text-center f-16 text-general br-6 mt-2">
                                    {{ i.nombre }}
                                </p>
                                <p class="text-general text-center f-20 f-600 mt-2">
                                    <el-tooltip content="Clics" placement="bottom" effect="light">
                                        <i class="icon-cursor" />
                                    </el-tooltip>
                                    {{ i.clicks }}
                                </p>
                            </div>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <div class="row mx-0">
                        <div class="col">
                            <p>Esta sección no contiene elementos</p>
                        </div>
                    </div>
                </template>
            </div>
            <div class="col-auto px-0 d-flex ml-3">
                <button type="button" class="btn-action border mr-2">
                    <el-popover
                    ref="optionsPopover"
                    placement="bottom"
                    trigger="click"
                    width="200"
                    popper-class="p-0 br-none"
                    >
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('editar')">
                            Editar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('ordenar')">
                            Ordenar sección
                        </div>
                        <div v-if="data.estado == 1" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('activar')">
                            Inactivar
                        </div>
                        <div v-if="data.estado == 0" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('inactivar')">
                            Activar
                        </div>
                        <div class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="emitirAccion('eliminar')">
                            Eliminar
                        </div>
                        <i slot="reference" class="icon-dots-vertical f-20 text-general cr-pointer" />
                    </el-popover>
                </button>
            </div>
        </div>
    </section>
</template>

<script>
import Service from '~/services/landing/landing_admin'
import { mapGetters } from 'vuex'

export default {
    props: {
        dosLineas: {
            type: Boolean,
            default: false
        },

        data: {
            type:Object,
            default:() => {}
        },

        changes: {
            type: Boolean,
            default: false
        },
    },
    data(){
        return {
            cargando: false,
            verData: false,
            primerLinea: [],
            segundaLinea: [],
        }
    },

    computed:{
        idSeccion(){
            return this.data.id
        },

        totalClicks(){
            return _.sumBy(this.primerLinea, 'clicks') + _.sumBy(this.segundaLinea, 'clicks')
        },

        sectionActive(){
            return parseInt(this.data.estado) == 0 ? false : true;
        },

        ...mapGetters({
            getListenerUpdater: 'landing/landing/getListenerUpdater',
        }),
    },

    watch:{
        changes(){ // Actualiza la información cuando se efectuan cambios desde el actualizar de las categorías
            this.getData();
        },
        getListenerUpdater(){ // Actualiza la información cuando se efectuan cambios desde el asignar de las categorías
            if(this.getListenerUpdater.type === 'actualizarData' && this.getListenerUpdater.value === 'categoria' && parseInt(this.getListenerUpdater.idSection) === parseInt(this.idSeccion)){
                this.getData();
            }
        },
    },

    mounted(){
        this.getData();
    },

    methods: {
        async getData(){
            console.log("consultare categorias");
            try {
                this.cargando = true;
                const {data} = await Service.detalleSeccion(this.idSeccion);
                if(data.categorias.length < 1){
                    return this.verData = false;
                }
                
                this.primerLinea = [];
                this.segundaLinea = [];

                if(this.dosLineas){
                    data.categorias.map((e, index) => {
                        let num = index + 1;
                        e.index = num;
                        if((num % 2) == 0){
                            this.segundaLinea = [...this.segundaLinea, e];
                        } else {
                            this.primerLinea = [...this.primerLinea, e];
                        }
                    });
                } else {
                    this.primerLinea = data.categorias;
                }

                this.verData = true;
            } catch(e){
                this.error_catch(e)
            } finally {
                this.cargando = false;
            }
        },

        leftScroll(){
            let container = document.getElementById('container');
            this.sideScroll(container,'left',25,100,25);
        },

        rightScroll(){
            let container = document.getElementById('container');
            this.sideScroll(container,'right',25,100,25);
        },

        sideScroll(element,direction,speed,distance,step){
            let scrollAmount = 0;
            var slideTimer = setInterval(function(){
                if(direction == 'left'){
                    element.scrollLeft -= step;
                } else {
                    element.scrollLeft += step;
                }
                scrollAmount += step;
                if(scrollAmount >= distance){
                    window.clearInterval(slideTimer);
                }
            }, speed);
        },

        emitirAccion(tipo){
            let accion = { tipo:tipo, idSeccion: this.idSeccion, seccion:'categoria' };
            this.$refs.optionsPopover.doToggle();
            this.$emit('acciones', accion);
        },
    }
}
</script>

<style lang="scss" scoped>

.section-categorias-component:hover{
    background-color: rgb(220, 242, 252);    
}

.card-categoria{
    width: 144px;
    height: 277px;
    .nombre-categoria{
        height: 46px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        line-height: 19px;
    }
}

.break-line{
    flex-basis: 100%;
    height: 0;
}

</style>